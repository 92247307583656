import React, { useEffect, useState, useRef } from 'react';
import { Tabs, Tab } from 'react-bootstrap-tabs';

const SiteAdjustments = () => {
    return (
        <div className="planning">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">Production Plant Adjustments</h2>
                </div>
            </div>
            <div className="card">
                <div class="card-body">
                    <Tabs activeHeaderStyle={{ background: 'transparent' }}>
                        <Tab label="Create Production Plant Adjustments">
                        </Tab>
                        <Tab label="Edit Production Plant Adjustments">
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

export default SiteAdjustments;