import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { login } from '../pages/const';
import Loader from './Loader';


// import request from 'superagent';

const Login = (props) => {

    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: 'onChange'
    });
    const [showErrorMessage, setShowErroMessage] = useState(false);
    const [errorMessage, setErroMessage] = useState('');
    const [showLoader, setShowLoader] = useState(false);


    // async function onFormSubmit() {
       
       
    
       
    //       const email = "miway";
    //       const password = "Mayora@123";
    //       try {
    //         const token = await request
    //           .post(
    //             `${process.env.REACT_APP_KEYCLOAK_DOMAIN}/realms/${process.env.REACT_APP_RELM}/protocol/openid-connect/token`
    //           )
    //           .set('content-type', 'application/x-www-form-urlencoded')
    //           .send({
    //             username: email,
    //             password: password,
    //             grant_type: process.env.REACT_APP_GRANT_TYPE,
    //             client_id: process.env.REACT_APP_CLIENT_ID,
    //             redirect_url: process.env.REACT_APP_REDIRECT_URL
    //           });
    
    //           if (token.status == 200) {
    //             const expiresIn = Date.now() + token.body.expires_in * 1000; // Convert seconds to milliseconds
    //             sessionStorage.setItem("expiretokenTime", expiresIn);
    //             console.log("token data is ....", token.body.expires_in);
              
    //             const userData = await request
    //               .get(
    //                 `${process.env.REACT_APP_KEYCLOAK_DOMAIN}/realms/${process.env.REACT_APP_RELM}/protocol/openid-connect/userinfo`
    //               )
    //               .set('content-type', 'application/x-www-form-urlencoded')
    //               .set('Authorization', `Bearer ${token?.body?.access_token}`);
              
    //             if (userData.body && userData.status) {
    //               console.log("Data is .....", userData.body);
    //               localStorage.setItem(
    //                 'RW_AUTH_DATA',
    //                 JSON.stringify({
    //                   access_token: token.body.access_token,
    //                   ...userData.body
    //                 })
    //               );
                 
    //             }
    //           }
              
    //       } catch (error) {
    //        console.log(error)
    //       }
    //     }
      
    
      


    const onSubmit = async (data, e) => {
        e.preventDefault();
        let loginRequest = {
            emailId: data.username,
            password: data.password,
            partyType: data.partyType
        }
        setShowLoader(true)
        login(loginRequest).then(res => {
            if (res.data.status.code === 'SUCCESS') {
                setShowLoader(false)
                //  onFormSubmit()
                sessionStorage.setItem('partyType', data.partyType);
                sessionStorage.setItem('token', res.data.token.access_token)
                if (data.partyType === 'Supplier') {
                    props.history.push('/supplierPo');
                } else {
                    props.history.push('/dashboard');
                }
            } else {
                props.history.push('/login')
                setShowErroMessage(true)
                setErroMessage(res.data.status.message)
                setShowLoader(false)
            }
        }).catch((err) => {
            console.log(err)
        });

    }

    return (

        <section className='login-page'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-4  login-left' style={{ minHeight: '100vh' }}>
                        <div className='left-content'>
                            <div className='logo-placeholder'>
                                <span className='icon'>
                                    <img src='/dist/img/TechM.png' width={200} alt="RealCollab Icon"></img>
                                </span>
                            </div>
                            <div className='text'>
                                <p style={{ fontSize: 24,color:'black' }}>
                                    Blockchain-Powered Supplier Collaboration
                                </p>
                                <p style={{color:'black'}}>
                                    "Streamlining Procurement efficiency with blockchain for seamless supplier collaboration and risk management."
                                </p>
                            </div>
                            <div className='copy'>
                             
                            </div>
                        </div>
                    </div>
                    <div className='col-md-8'>
                        <div className='login-placeholder'>
                            <h2><stong>Welcome back!</stong></h2>
                            <p style={{ color: '#989898', marginBottom: 20 }}>Please enter your credentials to continue!</p>
                            {showErrorMessage ? <span className="err-msg" style={{ textAlign: 'center', fontSize: 16 }}>{errorMessage}</span> : ""}
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <label>Party Type</label>
                                <div className='mb-3'>
                                    <select
                                        className="form-control"
                                        aria-label="Default select example"
                                        {...register("partyType", { required: true })}
                                        name="partyType"
                                    >
                                        <option value="">Select</option>
                                        <option value="Linesight">Procurement Consultant</option>
                                        <option value="Customer">Customer</option>
                                        <option value="Customer Production Plant">Production Plant</option>
                                        <option value="Contractor">Contractor</option>
                                        <option value="Supplier">Supplier</option>

                                    </select>
                                    {errors.partyType && <div className="err-msg">Please select Party Type</div>}
                                </div>
                                <label>User Name / Email</label>
                                <div className='mb-3'>
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="User ID" name="username"
                                            {...register("username", { required: true })}
                                        />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fa fa-user-circle" />
                                            </div>
                                        </div>

                                    </div>
                                    {errors.username && <div className="err-msg">Please enter User Name/Email</div>}
                                </div>

                                <label>Password</label>
                                <div className='mb-3'>
                                    <div className="input-group">
                                        <input type="password" className="form-control" placeholder="Password" name="password"
                                            {...register("password", { required: true })}
                                        />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-lock" />
                                            </div>
                                        </div>
                                    </div>
                                    {errors.password && <div className="err-msg">Please enter password</div>}
                                </div>
                                <div className="row" style={{ marginBottom: 15 }}>
                                    <div className="col-6">
                                        <div className="icheck-primary">
                                            <input type="checkbox" id="remember" />
                                            <label htmlFor="remember">
                                                Remember Me
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-6" style={{ marginTop: 10, textAlign: 'right' }}>
                                        <NavLink exact to="/forgotpassword" className="nav-link">
                                            Forgot password?
                                        </NavLink>
                                        {/* <a href="forgotpassword" title='Click here to reset your password'>Forgot password?</a> */}
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <button type="submit" className="btn btn-primary btn-block signin">Sign In</button>
                                    </div>
                                </div>
                                {showLoader ? <Loader /> : ''}
                            </form>
                        </div>

                    </div>

                </div>

            </div>

        </section>

    );
}
export default Login;