import DataTable from "react-data-table-component";
import React, { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from "react-hook-form";
import Datepicker from 'react-datepicker'

const GRN = () => {
    const [showbuttons, setShowButtons] = useState(false);
    const [disabledRows, setDisabledRows] = useState([]);
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: 'onChange'
    });
    const handleCheckboxClick = (supplierID) => {
        // Check if the checkbox is already in the disabledRows state
        const isDisabled = disabledRows.includes(supplierID);
        setShowButtons(true)
        // If it's disabled, remove it from the state; otherwise, add it
        if (isDisabled) {
            setDisabledRows(disabledRows.filter(id => id !== supplierID));
        } else {
            setDisabledRows([...disabledRows, supplierID]);
        }
    };
    const isButtonDisabledForRow = (supplierID) => {
        // Check if the current supplierID is in the disabledRows state
        return disabledRows.includes(supplierID);
    };
    const columns = [
        {
            name: "Select",
            sortable: true,
            // selector: "select",
            minWidth: '80px',
            cell: row => <>
                <div className="cell-checkboxInput">
                    <input className="form-check-input ml-6" type="checkbox"
                        onClick={() => handleCheckboxClick(row.supplierID)}
                        value={row.supplierID}
                        id={row.supplierID} />
                </div>
            </>
        },
        {
            name: "Supplier ID",
            selector:(row)=>row.supplierID,
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Supplier",
            selector:(row)=>row.supplierName,
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "ORDER NO",
            selector:(row)=>row.orderNo,
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "INVOICE NO",
            selector:(row)=>row.invoiceNo,
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "INVOICE DATE",
            selector:(row)=>row.invoiceDate,
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Material ID",
            selector:(row)=>row.materialID,
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Material",
            selector:(row)=>row.materialName,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "QTY",
            selector:(row)=>row.quantity,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: "UOM",
            selector:(row)=>row.uom,
            sortable: true,
            minWidth: '50px'
        },
        {
            name: "UID",
            selector:(row)=>row.uid,
            sortable: true,
            minWidth: '80px'
        },
        {
            name: "GRN #",
            selector:(row)=>row.grn,
            sortable: true,
            minWidth: '80px'
        },
        {
            name: "GRN Date",
            selector:(row)=>row.grnDate,
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Truck #",
            selector:(row)=>row.truckNumber,
            sortable: true,
            minWidth: '150px'
        },
        {
            name: "Action",
          
            sortable: true,
            minWidth: '260px',
            cell: row => <>
                <div className='row m-1'>
                    <input
                        type="submit"
                        value="Approve"
                        className={`btn btn-outline-success ${isButtonDisabledForRow(row.supplierID) ? 'disabled' : ''}`}
                        disabled={isButtonDisabledForRow(row.supplierID)}
                    />
                    <input
                        type="reset"
                        value="Reject"
                        className={`btn btn-outline-danger ml-2 ${isButtonDisabledForRow(row.supplierID) ? 'disabled' : ''}`}
                        disabled={isButtonDisabledForRow(row.supplierID)}
                    />
                </div>
            </>
        }
    ];
    const data = [
        {
            grn: "GRN011511",
            grnDate: "2023-11-15T00:00:00.000Z",
            materialID: "MTR005",
            materialName: "floor Tile 12*12",
            ponumber: "PO011511",
            quantity: 12,
            siteID: "CST001",
            supplierID: "SUPLR001",
            supplierName: "Kajaria Ceramics",
            truckNumber: "123",
            uid: "FLOOR001",
            uom: "BOX"
        },
        {
            grn: "GRN011511",
            grnDate: "2023-11-15T00:00:00.000Z",
            materialID: "MTR005",
            materialName: "floor Tile 12*12",
            ponumber: "PO011511",
            quantity: 12,
            siteID: "CST001",
            supplierID: "SUPLR005",
            supplierName: "Kajaria Ceramics",
            truckNumber: "123",
            uid: "FLOOR001",
            uom: "BOX"
        }
    ]
    return (
        <div>
            <div className="row">
                <div className="col-md-4">
                    {/* <form action="simple-results.html">
                        <div class="input-group">
                            <input type="search" className="form-control form-control-md"
                                placeholder="Search by Material/Contractor/Production Plant"
                            //value={searchTerm}
                            //onKeyUp={(e) => setSearchTerm(e.target.value)}
                            />
                            <div class="input-group-append">
                                <button type="submit" className="btn btn-md btn-default">
                                    <i class="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </form> */}
                </div>
                <div className="col-md-8">
                    {showbuttons &&
                        (
                            <ul class="controls">
                                <li><button type="button" class="btn btn-block btn-outline-success btn-sm">
                                    Approve</button></li>
                                <li><button type="button" class="btn btn-block btn-outline-danger btn-sm">
                                    Reject</button></li>
                            </ul>
                        )
                    }
                </div>
            </div>
            <form>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className="form-group">
                            <label htmlFor>Production Plant</label>
                            <select class="form-control form-control-sm" name="farmer"
                                {...register("siteID", {
                                    // onChange: (e) => { onFarmerChange(e) }
                                })}
                            >
                                <option value="">-Select-</option>

                            </select>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="form-group">
                            <label htmlFor>Supplier</label>
                            <select class="form-control form-control-sm" name="farmer"
                                {...register("supplier", {
                                    required: "Please select Supplier",
                                    // onChange: (e) => { onFarmerChange(e) }
                                })}
                            >
                                <option value="">-Select-</option>

                            </select>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="form-group">
                            <label htmlFor>From Date</label>
                            <Datepicker
                                // selected={fromDate}
                                // onChange={fromdateSelector}
                                //showTimeSelect
                                //setDuration(moment(closeDate).diff(batchSummary.startDate))
                                placeholderText="From Date"
                                //minDate={new Date(moment(batchSummary.startDateTime).format('YYYY-MM-DD'))}
                                dateFormat="dd-MMM-yyyy"
                                name="fromDate"
                                className="form-control form-control-sm"
                            >
                            </Datepicker>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="form-group">
                            <label htmlFor>To Date</label>
                            <Datepicker
                                // selected={toDate}
                                // onChange={todateSelector}
                                //showTimeSelect
                                //setDuration(moment(closeDate).diff(batchSummary.startDate))
                                placeholderText="To Date"
                                maxDate={new Date()}
                                //minDate={new Date(moment(batchSummary.startDateTime).format('YYYY-MM-DD'))}
                                dateFormat="dd-MMM-yyyy"
                                name="toDate"
                                className="form-control form-control-sm"
                            >
                            </Datepicker>
                        </div>
                    </div>
                </div>
            </form>
            <br></br>
            <div className='row'>
                <div className='col-md-12'>
                    <DataTable
                        columns={columns}
                        data={data}
                        //defaultSortField="templateID"
                        pagination
                        dense
                        selectableRowsHighlight='true'
                        compact
                        highlightOnHover='true'
                    />
                </div>
            </div>
        </div>
    )
}

export default GRN