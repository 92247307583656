import DataTable from "react-data-table-component";
import React, { useEffect, useState, useRef } from 'react';

const ContractorMasterList = () => {

    const columns = [
        {
            name: "Contractor ID",
            selector: "contractorID",
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Contractor Name",
            selector: "contractorName",
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Production Plant ID",
            selector: "siteID",
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Admin Mail ID",
            selector: "adminMailID",
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Admin Contact",
            selector: "adminContact",
            sortable: true,
            minWidth: '60px'
        },
        {
            name: "Address",
            selector: "address",
            sortable: true,
            minWidth: '50px'
        },
        {
            name: "Action",
            selector: "",
            sortable: true,
            minWidth: '150px'
        }
    ];

    const [data, setData] = useState([]);

    return (
        <>
            <DataTable
                columns={columns}
                data={data}
                //defaultSortField="templateID"
                pagination
                dense
                selectableRowsHighlight='true'
                compact
                highlightOnHover='true'
            />
        </>
    )

}

export default ContractorMasterList;