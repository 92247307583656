import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Button } from 'react-bootstrap';
import Datepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { env } from './const';

const InwardList = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm({ mode: 'onChange' });

    const [selectedDate, setSelectedDate] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [data, setData] = useState([]);
    const [siteData, setSiteData] = useState([]);
    const [supplierData, setSupplierData] = useState([]);
    const [show, setShow] = useState(false);
    const [QRcode, setQRcode] = useState('');

    const columns = [
        {
            name: 'QR Code',
            sortable: true,
            selector: row => row.qrcode,
            minWidth: '80px',
            cell: row => (
                <i
                    className='fas fa-qrcode'
                    style={{ fontSize: 20 }}
                    onClick={() => openQR(row.qrcode)}
                ></i>
            ),
        },
        {
            name: 'Supplier ID',
            selector: row => row.supplierID,
            sortable: true,
            minWidth: '130px',
        },
        {
            name: 'Supplier',
            selector: row => row.supplierName,
            sortable: true,
            minWidth: '130px',
        },
        {
            name: 'Material ID',
            selector: row => row.materialID,
            sortable: true,
            minWidth: '130px',
        },
        {
            name: 'Material',
            selector: row => row.materialName,
            sortable: true,
            minWidth: '200px',
        },
        {
            name: 'QTY',
            selector: row => row.quantity,
            sortable: true,
            minWidth: '60px',
        },
        {
            name: 'UOM',
            selector: row => row.uom,
            sortable: true,
            minWidth: '50px',
        },
        {
            name: 'UID',
            selector: row => row.uid,
            sortable: true,
            minWidth: '80px',
        },
        {
            name: 'GRN #',
            selector: row => row.grn,
            sortable: true,
            minWidth: '80px',
        },
        {
            name: 'GRN Date',
            selector: row => row.grnDate,
            sortable: true,
            minWidth: '130px',
        },
        {
            name: 'Truck #',
            selector: row => row.truckNumber,
            sortable: true,
            minWidth: '150px',
        },
    ];

    const openQR = (qrcode) => {
        setShow(true);
        setQRcode(qrcode);
    };

    const fetchData = async () => {
        try {
            const [inwardRes, siteRes, supplierRes] = await Promise.all([
                axios.get(env.produrl + '/entry/inwardList'),
                axios.get(env.produrl + '/misc/sitemaster'),
                axios.get(env.produrl + '/misc/suppliermaster')
            ]);
            setData(inwardRes.data.list);
            setSiteData(siteRes.data.list);
            setSupplierData(supplierRes.data.list);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        // fetchData();
    }, []);

    return (
        <>
            <form>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className="form-group">
                            <label htmlFor="siteID">Production Plant</label>
                            <select className="form-control form-control-sm" name="siteID" {...register("siteID")}>
                                <option value="">-Select-</option>
                                {siteData.map(item => (
                                    <option key={item.id} value={item.id}>
                                        {item.siteName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="form-group">
                            <label htmlFor="supplier">Supplier</label>
                            <select className="form-control form-control-sm" name="supplier" {...register("supplier", { required: "Please select Supplier" })}>
                                <option value="">-Select-</option>
                                {supplierData.map(item => (
                                    <option key={item.id} value={item.id}>
                                        {item.supplierName}
                                    </option>
                                ))}
                            </select>
                            {errors.supplier && <span className="text-danger">{errors.supplier.message}</span>}
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="form-group">
                            <label htmlFor="fromDate">From Date</label>
                            <Datepicker
                                selected={fromDate}
                                onChange={setFromDate}
                                placeholderText="From Date"
                                dateFormat="dd-MMM-yyyy"
                                className="form-control form-control-sm"
                            />
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className="form-group">
                            <label htmlFor="toDate">To Date</label>
                            <Datepicker
                                selected={toDate}
                                onChange={setToDate}
                                placeholderText="To Date"
                                maxDate={new Date()}
                                dateFormat="dd-MMM-yyyy"
                                className="form-control form-control-sm"
                            />
                        </div>
                    </div>
                </div>
            </form>
            <br />
            <DataTable
                columns={columns}
                data={data}
                pagination
                dense
                selectableRowsHighlight
                highlightOnHover
            />
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Body>
                    <center>
                        <img src={QRcode} alt="QR Code" style={{ width: '60%' }} />
                    </center>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default InwardList;
