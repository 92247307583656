
const trackingInfo = {
  name: "Batch: KURKURE-001",
  children: [
    {
      name: "Transport: SOYA-001",
      info: {
        products: [
          "Frito Lay",
          "	Cheetos Flamin' Hot Twisted flavour",
          "Quaker Oats",
        ],
        temparature: ["5", "8", "No Deviation", "no"],
      },
      children: [
        {
          name: "Storage: North Cold Storage Warehouse ",
          info: {
            temperature: ["5", "8", "No Deviation", "no"],
            safetyChecks: [
              "Pest Control",
              "Humidity & Moisture Control",
              "Sanitation and Cleanliness",
              "Fire Safety",
              "Air Quality",
              "CCTV Monitoring",
              "Access Control",
              "Visitors Log",
            ],
            ComplienceRegulatoryStandard: ["Food Safety Certification"],
          },
          children: [
            {
              name: "Processing: Flaking ",
              info: {
                date: ["2024-07-25 08:00", "2024-07-27 08:00"],
               
                  data: [
                    "Moisture Content",
                    "Protein and Oil Content",
                    "Foreign Matter",
                    "Physical Damage",
                    "Color",
                    "Seed Purity"
                  ]},
             
              children: [
                {
                  name: "Supplier: Beans Suppliers",
                  info: {
                    data: [
                      "SUPP01",
                      "Truck IA-01-1234",
                     
                      "2024-07-01 06:00",
                    ],
                  },

                  children: [
                    {
                      name: "Farm: Soya Bean Plantation - Iowa Soya Growers",
                      info: {
                        data: [ 
                          "Grupo",
                          "Mato Grosso region",
                          "Roundtable on Responsible Soy (RTRS)",
                        ],
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Transport: SOYA-002",
      info: {
        products: ["Frito Lay", "	Cheetos Flamin' Hot Twisted flavour"],
        temparature: ["5", "8", "No Deviation", "no"],
      },
      children: [
        {
          name: "Storage: Central Cold Storage Warehouse ",
          info: {
            temperature: ["5", "8", "No Deviation", "no"],
            safetyChecks: [
              "Pest Control",
              "Humidity & Moisture Control",
              "Sanitation and Cleanliness",
              "Fire Safety",
              "Air Quality",
              "CCTV Monitoring",
              "Access Control",
              "Visitors Log",
            ],
            ComplienceRegulatoryStandard: ["Food Safety Certification"],
          },
          children: [
            {
              name: "Processing: Flaking and Extraction",
              info: {
                date: ["2024-07-25 08:00", "2024-07-27 08:00"],
               
                  data: [
                    "Moisture Content",
                    "Protein and Oil Content",
                    "Foreign Matter",
                    "Physical Damage",
                    "Color",
                    "Seed Purity"
                  ]},
              children: [
                {
                  name: "Supplier: Northern Grain Partners",
                  children: [
                    {
                      name: "Farm: Soya Farm - Ohio Soya Producers",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Transport: COCOA-002",
      info: {
        products: [
          "Frappucino Coffee",
          "Quaker Rice Thins Cocoa Crunch",
          "Quaker choclate chip cookies",
        ],
        temparature: ["18", "20", "No Deviation", "no"],
      },
      children: [
        {
          name: "Storage: Dry Storage Warehouse",
          info: {
            temperature: ["3", "7", "No Deviation", "no"],
            safetyChecks: [
              "Pest Control",
              "Humidity & Moisture Control",
              "Sanitation and Cleanliness",
              "Fire Safety",
              "Air Quality",
              "CCTV Monitoring",
              "Access Control",
              "Visitors Log"
            ],
            ComplienceRegulatoryStandard: [
              "Fire and Safety Regulations"
            ]
          },
          children: [
            {
              name: "Processing: Cleaning & Grinding Cocoa",
              info: {
                date: ["2024-07-25 08:00", "2024-07-27 08:00"], // Adjust dates as necessary
                data: [
                  "Moisture Content Check",
                  "Bean Sorting and Grading",
                  "Fermentation Quality Check",
                  "Mold and Pest Contamination",
                  "Flavor and Quality Assessment"
                ],
              }
,              
              children: [
                {
                  name: "Supplier: Cocoa Beans Suppliers",
                  info: {
                    data: [
                      "SUPP06",
                      "Truck CA-12-2345",
                      "2024-07-03 07:00"
                    ],
                  },
                  
                  children: [
                    {
                      name: "Farm: Cocoa Plantation - California Cocoa Fields",
                      info: {
                        data: [
                          "Café Fazenda Ambiental Fortaleza",
                          "Minas Gerais region",
                          "UTZ or Rainforest Alliance Certified"
                        ],
                      }
                      
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Transport: RUBBER-003",
      info: {
        products: ["Pepsi Rubber Ball Bottle"],
        temparature: ["15", "25", "No Deviation", "no"],
      },
      children: [
        {
          name: "Storage: North Temperature Controlled Warehouse ",
          info :{
            temperature: ["5", "8", "No Deviation", "no"],
            safetyChecks: [
              "Pest Control",
              "Fire Hazard Prevention",
              "Handling Equipment Safety",
              
             
            ],
            ComplienceRegulatoryStandard: [ "ISO 9001","Fire and Safety Regulations"],
          },
          children: [
            {
              name: "Processing: Extrusion and Molding",
              info: {
                date: ["2024-07-25 08:00", "2024-07-27 08:00"], // Adjust dates as necessary
                data: [
                  "Visual Inspection",
                  "Dimensional Accuracy",
                  "Hardness Test (Durometer Testing)",
                  "Tensile Strength and Elongation Testing"
                ],
              }
,              
              children: [
                {
                  name: "Supplier: Rubber Suppliers",
                  info: {
                    data: [
                      "SUPP03",
                      "Truck WA-12-321",
                      "2024-07-02 07:00"
                    ],
                  }
,                  
                  children: [
                    {
                      name: "Farm: Rubber Plantation - Washington Rubber Co.",
                      info: {
                        data: [
                          "Plantar S/A",
                          "Minas Gerais region",
                          "Forest Stewardship Council (FSC)"
                        ],
                      }
                      
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Transport: DAIRY-004",
      info: {
        products: [
          "Quaker yogurt granola bars",
          "Quaker Milk choclate Oatmeal Cookies",
          "Nacho Cheese Doritos",
        ],
        temparature: ["0", "22", "No Deviation", "no"],
      },
      children: [
        {
          name: "Storage: West Cold Storage Warehouse ",
          info:{
            temperature: ["1", "12", "No Deviation", "no"],
            safetyChecks: [
              "Pest Control",
              "Humidity & Moisture Control",
              "Sanitation and Cleanliness",
              "Fire Safety",
              "Air Quality",
              "CCTV Monitoring",
              "Access Control",
              "Visitors Log",
            ],
            ComplienceRegulatoryStandard: ["Food Safety Certification"],
          },
          children: [
            {
              name: "Processing: Milk Pasteurizing & Processing",
              info: {
                date: ["2024-07-25 08:00", "2024-07-27 08:00"], // Adjust dates as necessary
                data: [
                 
                  "Microbiological Testing",
                  "Chemical Analysis",
                  "Allergen Testing",
                  "Packaging Integrity"
                ],
              },
              children: [
                {
                  name: "Supplier: Dairy Suppliers",
                  info: {
                    data: [
                      "SUPP04",
                      "Truck TX-12-2231",
                      "2024-07-03 07:00"
                    ],
                  }
                  ,
                  children: [
                    {
                      name: "Farm: Dairy Farm - Texas Dairy Farm",
                      info: {
                        data: [
                          "Café Fazenda Ambiental Fortaleza",
                          "Minas Gerais region",
                          "Global Dairy Farmers, sustainable farming initiative"
                        ],
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Transport: WOOD-005",
      info: {
        products: ["Packaging", "Paper Bottle"],
        temparature: ["10", "25", "Deviated to 26 C For 1 Min", "yes"],
      },
      children: [
        {
          name: "Storage: Wood Warehouse",
          info: {
            temperature: ["12", "18", "No Deviation", "no"],
            safetyChecks: [
              "Pest Control",
              "Humidity & Moisture Control",
              "Sanitation and Cleanliness",
              "Fire Safety",
              "Air Quality",
              "CCTV Monitoring",
              "Access Control",
              "Visitors Log"
            ],
            ComplienceRegulatoryStandard: [
              "Fire and Safety Regulations"
            ]
          },
          children: [
            {
              name: "Processing: Shaping and Sanding",
              info: {
                date: ["2024-07-25 08:00", "2024-07-27 08:00"], // Adjust dates as necessary
                data: [
                  "Moisture Content Testing",
                  "Dimensional Accuracy",
                  "Surface Quality Inspection",
                  "Grain and Defect Inspection",
                  "Hardness and Durability Testing",
                  "Treatment Verification"
                ],
              }
,              
              children: [
                {
                  name: "Supplier: Wood Suppliers",
                  info: {
                    data: [
                      "SUPP05",
                      "Truck TX-12-2231",
                      "2024-07-04 07:00"
                    ],
                  },
                  children: [
                    {
                      name: "Farm: Wood Plantation - Florida Gold Roots",
                      info: {
                        data: [
                          "Plantar S/A",
                          "Minas Gerais region",
                          "Forest Stewardship Council (FSC)"
                        ],
                      }
                      
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Transport: PALM-OIL-007",

      info: {
        products: ["Lays", "Doritos", "Cheetos"],
        temparature: ["10", "15", "Deviated to 17 C For 3 Min", "yes"],
      },
      children: [
        {
          name: "Storage: Bulk Liquid Storage Facility",
          info: {
            temperature: ["8", "10", "No Deviation", "no"],
            safetyChecks: [
              "Pest Control",
              "Humidity & Moisture Control",
              "Sanitation and Cleanliness",
              "Fire Safety",
              "Air Quality",
              "CCTV Monitoring",
              "Access Control",
              "Visitors Log"
            ],
            ComplienceRegulatoryStandard: [
              "Fire and Safety Certification"
            ]
          },
          children: [
            {
              name: "Processing: Pressing & Refining",
              info: {
                date: ["2024-07-25 08:00", "2024-07-27 08:00"], 
                data: [
                  "Quality & Purity Checks",
                  "Chemical and Contaminant Testing",
                  "Traceability and Sustainability Checks",
                  "Sensory Testing",
                  "Packaging Integrity Checks"
                ],
              },
              
              
              children: [
                {
                  name: "Supplier: Palm Bunch Suppliers",
                  info: {
                    data: [
                      "SUPP02",
                      "Truck CA-12-4435",
                      "2024-07-01 07:00"
                    ],
                  }
,                  
                  children: [
                    {
                      name: "Farm: Palm Plantation - Florida Sunflower Fields",
                      info: {
                        data: [
                          "Agropalma",
                          "Pará region",
                          "Roundtable on Sustainable Palm Oil (RSPO)"
                        ],
                      }
                      
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default trackingInfo;
