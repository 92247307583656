import React, { useState, useRef, useEffect, useCallback } from "react";
import Tree from "react-tree-graph";
import "react-tree-graph/dist/style.css";

import rawNode from "./trackingInfo";
import rootData from "./FinishGoodsData";

import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import Offcanvas from "react-bootstrap/Offcanvas";
import ListGroup from "react-bootstrap/ListGroup";
import { Box, Drawer } from "@mui/material";
import { Card } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const DEFAULT_DEPTH = 9;

const cloneWithDepth = (object, depth = DEFAULT_DEPTH) => {
  if (depth === -1) return undefined;
  if (typeof object !== "object") return object;

  if (Array.isArray(object)) {
    return object
      .map((val) => cloneWithDepth(val, depth - 1))
      .filter((val) => val !== undefined);
  }

  const clone = {};

  for (const key in object) {
    if (typeof object[key] === "object" && depth - 1 === -1) {
      continue;
    }

    const clonedValue = cloneWithDepth(object[key], depth - 1);

    if (clonedValue !== undefined) {
      clone[key] = clonedValue;
    }
  }

  return clone;
};

const useWindowInnerSize = () => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const handleResize = useCallback(() => {
    setInnerWidth(window.innerWidth);
    setInnerHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return { innerWidth, innerHeight };
};

const Trackings = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const [data, setData] = useState();
  const [transport] = useState([
    {
      batch_id: "KURKURE-001",
      vehicle: "Truck TX-12-AB-101",
      driver: "Alex Martinez",
      time: "2024-08-01 08:00",
      destination: "Processing Plant: Frito-Lay Plant, Texas",
    },
    {
      batch_id: "CORN-001",
      source: "Corn Farm: Iowa Corn Growers",
      vehicle: "Truck IA-01-1234",
      driver: "John Smith",
      time: "2024-07-30 06:00",
      destination: "Supplier: Grain Suppliers",
    },
    {
      batch_id: "RICE-002",
      source: "Rice Farm: California Rice Fields",
      vehicle: "Truck CA-12-2345",
      driver: "Lisa Johnson",
      time: "2024-07-30 07:00",
      destination: "Supplier: Flour Suppliers",
    },
    {
      batch_id: "CHICKPEA-003",
      source: "Chickpea Farm: Washington Chickpea Co.",
      vehicle: "Truck WA-18-3456",
      driver: "Michael Davis",
      time: "2024-07-30 08:00",
      destination: "Supplier: Chickpea Suppliers",
    },
    {
      batch_id: "CUMIN-004",
      source: "Cumin Farm: Texas Spice Farm",
      vehicle: "Truck TX-14-ST-234",
      driver: "Laura Green",
      time: "2024-07-30 09:00",
      destination: "Supplier: Cumin Suppliers",
    },
    {
      batch_id: "TURMERIC-005",
      source: "Turmeric Farm: Florida Gold Roots",
      vehicle: "Truck FL-56-UV-789",
      driver: "Chris White",
      time: "2024-07-30 10:00",
      destination: "Supplier: Turmeric Suppliers",
    },
    {
      batch_id: "CHILI-006",
      source: "Chili Farm: New Mexico Hot Peppers",
      vehicle: "Truck NM-78-XY-123",
      driver: "Emma Brown",
      time: "2024-07-30 11:00",
      destination: "Supplier: Chili Powder Suppliers",
    },
    {
      batch_id: "OIL-007",
      source: "Oil Plantation: Florida Sunflower Fields",
      vehicle: "Truck FL-56-UV-789",
      driver: "Kevin Thompson",
      time: "2024-07-30 12:00",
      destination: "Supplier: Oil Suppliers",
    },
    {
      batch_id: "SALT-008",
      source: "Salt Mine: Utah Salt Flats",
      vehicle: "Truck UT-78-XY-123",
      driver: "Angela Martinez",
      time: "2024-07-30 13:00",
      destination: "Supplier: Salt Suppliers",
    },
  ]);
  const [path, setPath] = useState([]);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);
  const { innerWidth, innerHeight } = useWindowInnerSize();
  const canvasWrapper = useRef(null);
  const [ing, setShowTrack] = useState(false);
  const [drop, setDrop] = useState("raw");

  const [show, setShow] = useState(false);

  const [showStorage, setShowStorage] = useState(false);

  const handleStorage = () => {
    setShowStorage(false);
  };

  const [searchKey, setSearchKey] = useState("");
  const [relateProd, setRealteProd] = useState([]);
  const [relateProdTemp, setRealteProdTemparature] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const setCanvasSize = useCallback(() => {
    if (canvasWrapper.current) {
      const { clientWidth, clientHeight } = canvasWrapper.current;
      setCanvasWidth(clientWidth);
      setCanvasHeight(clientHeight);
    }
  }, []);
  useEffect(() => {
    setCanvasSize();
    window.addEventListener("resize", setCanvasSize);
    return () => window.removeEventListener("resize", setCanvasSize);
  }, [setCanvasSize, ing]);
  useEffect(() => {
    setCanvasSize(); // Update size on window resize
  }, [innerWidth, innerHeight, setCanvasSize]);
  const changeNode = ({ node, path }) => {
    setPath(path);
    setData(node);
  };
  const findPathtoBack = (path) => {
    console.log(drop, batch);
    if (drop == "raw") {
      changeNode(findNodeForFinish(path));
    }
    if (drop == "finish") {
      console.log("why am i root data");
      changeNode(findNode(path));
    }
  };
  const findNode = (key, node = rootData, parentPath = []) => {
    console.log("Root Data is ...", rootData, key);

    const path = [...parentPath, node.name];
    if (node.name === key) {
      return { node: cloneWithDepth(node), path };
    }
    if (Array.isArray(node.children)) {
      for (const child of node.children) {
        const found = findNode(key, child, path);
        if (found) return found;
      }
    }
  };

  const findNodeForFinish = (key, node = rawNode, parentPath = []) => {
    console.log("Raw Node is ...", rawNode);
    const path = [...parentPath, node.name];
    if (node.name === key) {
      return { node: cloneWithDepth(node), path };
    }
    if (Array.isArray(node.children)) {
      for (const child of node.children) {
        const found = findNodeForFinish(key, child, path);
        if (found) return found;
      }
    }
  };

  const [plant, showPlant] = useState(false);
  const [track, showTracking] = useState(false);
  const handletrackClose = () => showTracking(false);
  const handleplantClose = () => showPlant(false);
  const handleplantShow = () => showPlant(true);
  const [showkurkure, setkurkure] = useState(false);
  const handlekurkure = () => setkurkure(false);

  const [truckDetails, setTruckDetails] = useState([]);
  const getTruckdetails = (validate, id) => {
    let filtereddata = transport.filter((data) => data.batch_id === id);

    setTruckDetails(filtereddata);
    if (filtereddata.length > 0 && validate !== "Corn") {
      showTracking(true);
    } else {
      setTruckDetails([]); // Clear truckDetails if no match is found
    }
  };

  const [cornId, setCornId] = useState("");

  const [batch, setBatch] = useState();

  const [safetyChecks, setSaftey] = useState([]);
  const [Standards, setStandards] = useState([]);
  const [info, setInfo] = useState(false);
  const [processing, setProcesing] = useState([]);
  const [supplier, setsupplier] = useState([]);
  const [farm, setFarm] = useState([]);
  const [mainKey, setMainKey] = useState("");

  const handleInfo = () => {
    setInfo(false);
  };

  const handleClick = (event, key) => {
    let result;
    let keys = key.split(" ") || [];

    console.log("key is ............................", keys[0]);

    if (drop == "raw") {
      result = findNodeForFinish(key);
      getTruckdetails(keys[0], keys[3]);
    }

    if (drop == "finish") {
      result = findNode(key);
    }

    if (result) {
      const { node, path } = result;
      changeNode({ node, path });
    } else {
      console.log("Node not found:", key);
    }

    console.log(result.node.info.temperature, keys);
    if (keys[0] === "Storage:") {
      setSaftey(result.node.info.safetyChecks);
      setRealteProdTemparature(result.node.info?.temperature);
      setStandards(result.node.info?.ComplienceRegulatoryStandard);
      setShowStorage(true);
    }
    if (
      keys[0] == "Processing:" ||
      keys[0] == "Supplier:" ||
      keys[0] == "Farm:"
    ) {
      setInfo(true);
      setMainKey(keys[0]);
      setProcesing(result.node.info || []);
      setFarm(result.node.info.data || []);
      setsupplier(result.node.info.data || []);
    }
    let filterData = data.children.filter((data) => data.name == key);
    console.log("Filtered", filterData);
    if (keys[0] === "Transport:") {
      setSearchKey(key);
      setRealteProd(result.node.info.products);
      setRealteProdTemparature(result.node.info.temparature);
      console.log(result.node.info.temperature);
      handleShow();
    }
    if (keys[1] == "KURKURE-001") {
      setkurkure(true);
    }
    if (key.split(" ")[2] == "Frito-Lay") {
      showPlant(true);
    }
  };



  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const onSubmit = (data) => {
    setBatch(data.batch);

    setData(cloneWithDepth(rawNode));
    setPath([rawNode.name]);

    setShowTrack(true);
    setIsDropdownVisible(true);
  };

  return (
    <>
      <div className="row mb-2">
        <div className="col-sm-6">
          <h2 className="m-0 text-dark">TRACKING</h2>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="batch">Batch #</label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    style={{ textTransform: "uppercase" }}
                    placeholder="Enter Batch #"
                    {...register("batch", {
                      required: "Please enter Batch #",
                    })}
                  />
                  {errors.batch && (
                    <span className="err-msg">{errors.batch.message}</span>
                  )}
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <input
                  type="submit"
                  value="Submit"
                  className="btn btn-primary newBtn"
                />
                <input
                  type="reset"
                  value="Reset"
                  className="btn btn-secondary newBtn"
                />
              </div>
            </div>
          </form>
        </div>
        {isDropdownVisible && (
          <div className="row  ml-2">
            <div className="col-md-4">
              <label htmlFor="batch">Filter By: </label>
              <select
                className="form-control form-select form-select-sm"
                aria-label=".form-select-sm example"
                onChange={(e) => {
                  if (e.target.value === "raw") {
                    setDrop("raw");
                    setData(cloneWithDepth(rawNode));
                    setPath([rawNode.name]);
                  } else {
                    setDrop("finish");
                    setData(cloneWithDepth(rootData));
                    setPath([rootData.name]);
                  }
                }}
              >
                <option value="default">--Select an option--</option>
                <option value="raw" selected>
                  Raw Material Tracking
                </option>
                <option value="finish">Finished Goods Tracking</option>
              </select>
            </div>
          </div>
        )}
        {data && (
          <div
            style={{
              flexGrow: 1,
              display: ing ? "flex" : "none",
              flexDirection: "column",
            }}
          >
            <div className="ml-3 mt-2">
              <label>Current Path(Node) : </label>
              <div className="mt-1">
                {path.map((path) => (
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      ml: 2,
                      mb: 1,
                      cursor: data.name === path ? "" : "pointer",
                      color: data.name === path ? "white" : "black",
                    }}
                    key={path}
                    onClick={() => findPathtoBack(path)}
                  >
                    {path}
                  </Button>
                ))}
              </div>
            </div>
            <div
              style={{
                flexGrow: 1,
                height: "100vh",
                display: "flex",
                alignItems: "flex-start",
              }}
              ref={canvasWrapper}
            >
              <div
                style={{
                  width: canvasWidth,
                  height: canvasHeight,
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <Tree
                  animated
                  data={data}
                  width={canvasWidth}
                  height={canvasHeight}
                  nodeRadius={12}
                  gProps={{ className: "node", onClick: handleClick }}
                  margins={{ top: 20, bottom: 10, left: 20, right: 200 }}
                  textProps={{ color: "green" }}
                  onHover={(e) => console.log(e)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <Drawer open={show} onClose={handleClose} anchor={"right"}>
        <Card>
          <Card.Header as="h6">
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>PRODUCTS MADE USING : {searchKey.split(":")[1]}</div>

              <CloseIcon
                onClick={handleClose}
                sx={{ alignSelf: "end", color: "black", cursor: "pointer" }}
              />
            </Box>
          </Card.Header>
          <Card.Body>
            <ListGroup as="ol" numbered>
              {relateProd.map((data, index) => (
                <ListGroup.Item as="li">
                  {data} - Product ID: {data.split(" ")[0]}-
                  {data.split(" ").length + 1}
                </ListGroup.Item>
              ))}
            </ListGroup>
            <div style={{ marginBottom: 1 }}></div>
            <Card.Header as="h6">
              TEMPARATURE{" "}
              <FiberManualRecordIcon
                color={relateProdTemp[3] == "yes" ? "error" : "success"}
              />{" "}
            </Card.Header>
            <Card.Body>
              <ListGroup as="ol" numbered>
                <ListGroup.Item as="li">
                  {" "}
                  LCL : {relateProdTemp[0]} C
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  {" "}
                  UCL : {relateProdTemp[1]} C
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  {" "}
                  DEVIATION : {relateProdTemp[2]}{" "}
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card.Body>
          <Card.Header as="h6">TRACKING DETAILS</Card.Header>
          <Card.Body>
            <ListGroup as="ol" numbered>
              <ListGroup.Item as="li">
                Batch ID : {searchKey.split(":")[1]}
              </ListGroup.Item>
              <ListGroup.Item as="li">Driver : Alex Martinez</ListGroup.Item>
              <ListGroup.Item as="li">
                Arrival Time : 2024-08-01 08:00
              </ListGroup.Item>
              <ListGroup.Item as="li">
                Vehicle : Truck TX-12-AB-101
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>

          {/* <Card.Footer>
            <Button color="error" variant="contained" onClick={handleClose}>
              Close
            </Button>
          </Card.Footer> */}
        </Card>
      </Drawer>

      <Drawer open={plant} onClose={handleplantClose} anchor={"right"}>
        <Card>
          <Card.Header as="h6">
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>Processing Plant: Frito-Lay Plant, Texas</div>

              <CloseIcon
                onClick={handleplantClose}
                sx={{ alignSelf: "end", color: "black", cursor: "pointer" }}
              />
            </Box>
          </Card.Header>
          <Card.Body>
            <ListGroup as="ol" numbered>
              <ListGroup.Item as="li">
                Batch 001 Processing
                <br />
                Timeline - 2024-08-02 08:00
              </ListGroup.Item>
              <ListGroup.Item as="li">
                Extrusion Completed
                <br />
                Timeline - 2024-08-02 10:00
              </ListGroup.Item>
              <ListGroup.Item as="li">
                Frying Completed
                <br />
                Timeline - 2024-08-02 11:00
              </ListGroup.Item>
              <ListGroup.Item as="li">
                Seasoning Applied
                <br />
                Timeline - 2024-08-02 11:30
              </ListGroup.Item>
              <ListGroup.Item as="li">
                Batch 001 Packaging Completed
                <br />
                Timeline - 2024-08-02 12:00
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
          <Card.Header as="h6">
            TEMPARATURE{" "}
            <FiberManualRecordIcon
              color={relateProdTemp[3] == "yes" ? "error" : "success"}
            />{" "}
          </Card.Header>
          <Card.Body>
            <ListGroup as="ol" numbered>
              <ListGroup.Item as="li">
                {" "}
                LCL : {relateProdTemp[0]} C
              </ListGroup.Item>
              <ListGroup.Item as="li">
                {" "}
                UCL : {relateProdTemp[1]} C
              </ListGroup.Item>
              <ListGroup.Item as="li">
                {" "}
                DEVIATION : {relateProdTemp[2]}{" "}
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
      </Drawer>

      <Drawer open={track} onClose={handletrackClose} anchor={"right"}>
        <Card>
          <Card.Header as="h6">
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>Tracking Details</div>

              <CloseIcon
                onClick={handletrackClose}
                sx={{ alignSelf: "end", color: "black", cursor: "pointer" }}
              />
            </Box>
          </Card.Header>
          <Card.Body>
            {truckDetails.length > 0 || truckDetails.length == 4 ? (
              <ListGroup as="ol" numbered>
                <ListGroup.Item as="li">
                  Batch ID : {truckDetails[0].batch_id}
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  Driver : {truckDetails[0].driver}
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  Arrival Time : {truckDetails[0].time}
                </ListGroup.Item>
                <ListGroup.Item as="li">
                  Vehicle : {truckDetails[0].vehicle}
                </ListGroup.Item>
              </ListGroup>
            ) : (
              <p>No details available.</p>
            )}
          </Card.Body>
        </Card>
      </Drawer>

      <Drawer open={showkurkure} onClose={handlekurkure} anchor={"right"}>
        <Card>
          <Card.Header as="h6">
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>Tracking Details</div>

              <CloseIcon
                onClick={handlekurkure}
                sx={{ alignSelf: "end", color: "black", cursor: "pointer" }}
              />
            </Box>
          </Card.Header>
          <Card.Body>
            <ListGroup as="ol" numbered>
              <ListGroup.Item as="li">Batch ID : KURKURE-001</ListGroup.Item>
              <ListGroup.Item as="li">Driver : Alex Martinez</ListGroup.Item>
              <ListGroup.Item as="li">
                Arrival Time : 2024-08-01 08:00
              </ListGroup.Item>
              <ListGroup.Item as="li">
                Vehicle : Truck TX-12-AB-101
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
      </Drawer>

      <Drawer open={showStorage} onClose={handleStorage} anchor={"right"}>
        <Card>
          <Card.Header as="h6">
            Warehouse Temperature{" "}
            <FiberManualRecordIcon
              color={relateProdTemp[3] == "yes" ? "error" : "success"}
            />{" "}
            <CloseIcon
              onClick={handleStorage}
              sx={{ alignSelf: "end", color: "black", cursor: "pointer" }}
            />
          </Card.Header>
          <Card.Body>
            <ListGroup as="ol" numbered>
              <ListGroup.Item as="li">
                {" "}
                LCL : {relateProdTemp[0]} C
              </ListGroup.Item>
              <ListGroup.Item as="li">
                {" "}
                UCL : {relateProdTemp[1]} C
              </ListGroup.Item>
              <ListGroup.Item as="li">
                {" "}
                DEVIATION : {relateProdTemp[2]}{" "}
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
          <Card.Header as="h6">
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>Complience & Regulatory Standard</div>
            </Box>
          </Card.Header>
          <Card.Body>
            <ListGroup as="ol" numbered>
              {Standards.map((data, index) => (
                <ListGroup.Item as="li" key={index}>
                  {data} {"  "}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card.Body>
          <Card.Header as="h6">
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>Warehouse Safety Checks</div>
            </Box>
          </Card.Header>
          <Card.Body>
            <ListGroup as="ol" numbered>
              {safetyChecks.map((data, index) => (
                <ListGroup.Item as="li" key={index}>
                  {data} {"  "}
                  <CheckCircleIcon color="success" sx={{ float: "right" }} />
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card.Body>
        </Card>
      </Drawer>

      <Drawer open={info} onClose={handleInfo} anchor={"right"}>
        <Card>
          {mainKey == "Processing:" && (
            <>
              <Card.Header as="h6">
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>{mainKey}</div>
                  <CloseIcon
              onClick={handleInfo}
              sx={{ alignSelf: "end", color: "black", cursor: "pointer" }}
            />
                </Box>
              
              </Card.Header>
              <Card.Body>
                <ListGroup as="ol" numbered>
                  <ListGroup.Item as="li">
                    Starting Date :{processing.date[0]}
                  </ListGroup.Item>
                  <ListGroup.Item as="li">
                    Ending Date :{processing.date[1]}
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
              <Card.Header as="h6">
            <Box
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>Post Processing Quality Checks</div>
            </Box>
          </Card.Header>
          <Card.Body>
            <ListGroup as="ol" numbered>
              {processing.data.map((data, index) => (
                <ListGroup.Item as="li" key={index}>
                  {data} {"  "}
                  <CheckCircleIcon color="success" sx={{ float: "right" }} />
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card.Body>
            </>
          )}

          {mainKey === "Supplier:" && (
            <>
              <Card.Header as="h6"> <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>Supplier Details</div>
                  <CloseIcon
              onClick={handleInfo}
              sx={{ alignSelf: "end", color: "black", cursor: "pointer" }}
            />
                </Box></Card.Header>
              
              <Card.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <strong>Supplier Code:</strong>{ supplier[0]}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <strong>Vehicle:</strong> {supplier[1]}
                  </ListGroup.Item>
                 
                  <ListGroup.Item>
                    <strong>Time of Arrival:</strong> {supplier[2]}
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </>
          )}
          {mainKey == "Farm:" && (
            <>
         
                <Card.Header as="h6"> <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>Farm Details</div>
                  <CloseIcon
              onClick={handleInfo}
              sx={{ alignSelf: "end", color: "black", cursor: "pointer" }}
            />
                </Box></Card.Header>
                <Card.Body>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <strong>Farm Name:</strong> {farm[0]}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Location:</strong> {farm[1]}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Certification:</strong> {farm[2]}
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              
            </>
          )}
        </Card>
      </Drawer>
    </>
  );
};

export default Trackings;
