import DataTable from "react-data-table-component";
import React, { useState, useEffect } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { NavLink } from "react-router-dom";
import Datepicker from 'react-datepicker';
import { poList, poAction, poLinesList, userInfo, purchaseOrderInProduction, } from "../pages/const";
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';
import Swal from 'sweetalert2';
import moment from 'moment';
import { useForm, Controller } from "react-hook-form";
import { FaFileUpload } from "react-icons/fa";
import { SiMicrosoftexcel } from "react-icons/si";
const POReport = (props) => {
    const [offcanvas, setoffcanvas] = useState(false);
    const [partyType, setPartyType] = useState(sessionStorage.getItem('partyType'));
    const [data, setData] = useState([]);
    const [dataPOLines, setDataPOLines] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [siteList, setSiteList] = useState([])
    const [customerList, setCustomerList] = useState([])

    const handleChange = (rows) => {
        setSelectedRows(rows.selectedRows);
        if (rows.selectedRows.length > 0) {
            setShowButtons(true)
        } else {
            setShowButtons(false)
        }

    };
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="22" color="#333" />,
    });
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [poNumberValue, setPoNumberValue] = useState('');
    const [disabledRows, setDisabledRows] = useState([]);
    const [showbuttons, setShowButtons] = useState(false);
    const { register, handleSubmit, getValues, watch, formState: { errors } } = useForm({
        mode: 'onChange'
    });
    const isButtonDisabledForRow = (orderNumber) => {
        // Check if the current supplierID is in the disabledRows state
        return disabledRows.includes(orderNumber);
    };
    // const handleCheckboxClick = (orderNumber) => {
    //     // Check if the checkbox is already in the disabledRows state
    //     console.log(orderNumber,"ordernumber")
    //     const isDisabled = disabledRows.includes(orderNumber);

    //     // If it's disabled, remove it from the state; otherwise, add it
    //     if (isDisabled) {
    //         setDisabledRows(disabledRows.filter(id => id !== orderNumber));
    //     } else {
    //         setDisabledRows([...disabledRows, orderNumber]);

    //     }
    //     if(disabledRows.length === 0){

    //         setShowButtons(false)
    //     }else{
    //         setShowButtons(true)
    //     }

    //     console.log(disabledRows, "orderNumberdisable")
    // };
    const columns = [
        // {
        //     name: "Select",
        //     sortable: true,
        //     selector: "select",
        //     minWidth: '80px',
        //     cell: row => <>
        //         <div className="cell-checkboxInput">
        //             <input className="form-check-input ml-6" type="checkbox"
        //                 onClick={() => handleCheckboxClick(row.orderNumber)}
        //                 value={row.orderNumber}
        //                 id={row.orderNumber} />
        //         </div>
        //     </>
        // },
        {
            name: 'Purchase Order',
            selector: row => row.orderNumber,
            sortable: true,
            minWidth: "200px",
            cell: (row) => <a
                style={{
                    width: "200%",
                    lineHeight: 0.5,
                    color: "blue",
                    cursor: 'pointer'
                }}
                onClick={() => poLinesOverlay(row.orderNumber)}
            >
                {row.orderNumber}
            </a>
        },
        {
            name: 'Confirm By',
            selector: row => moment(row.orderRecommendedDate).format('DD-MMM-YYYY'),
            sortable: true,
            minWidth: '150px',
            cell: (row) => {
                if (row.risk === 1) {
                    return <span style={{ color: 'red' }}> {moment(row.orderRecommendedDate).format('DD-MMM-YYYY')}</span>
                }
                else {
                    return <span> {moment(row.orderRecommendedDate).format('DD-MMM-YYYY')}</span>
                }
            }
        },
        partyType === 'Supplier' ? {
            name: 'Customer ID',
            selector: row => row.customerId,
            sortable: true,
            minWidth: '150px',
        } : {
            name: 'Supplier ID',
            selector: row => row.supplierId,
            sortable: true,
            minWidth: '150px',
        },
        partyType === 'Supplier' ? {
            name: 'Customer Name',
            selector: row => row.customerName,
            sortable: true,
            minWidth: '200px',

        } : {
            name: 'Supplier Name',
            selector: row => row.supplierName,
            sortable: true
        },
        {
            name: 'Delivery Plant',
            selector: row => row.customerSiteId,
            sortable: true,
            minWidth: '150px',
        },
        {
            name: 'CREATED BY',
            selector: row => row.createdBy,
            sortable: true,
            minWidth: '250px',
        },
        {
            name: 'CREATED DATE',
            selector: row => moment(row.createdAt).format('DD-MMM-YYYY'),
            sortable: true,
            minWidth: '150px',
        },
        partyType !== 'Supplier' &&
        {
            name: 'STATUS',
            selector: row => row.risk,
            sortable: true,
            cell: row => {
                if (row.risk === 1) {
                    return <span>Risk</span>
                }
                else {
                    return <span>On Track</span>
                }
            }
        },
        {
            name: 'Documents',
            selector: row => row => moment(row.createdAt).format('DD-MMM-YYYY'),
            sortable: true,
            cell: (row) => <>
                <FaFileUpload style={{ cursor: "pointer", color: "blue" }} />
            </>
        },
        // partyType === 'Supplier' &&
        // {
        //     name: 'ACTION',
        //     selector: row => row.orderStatus,
        //     minWidth: '150px',
        //     cell: row => <>
        //         <div className='row m-1'>
        //             <input type="submit" value="Confirm" class="btn btn-outline-success btn-sm" />
        //         </div>
        //     </>
        // }
        // partyType !== 'Supplier' && {
        //     name: 'ACTION',
        //     selector: row => row.orderStatus,
        //     minWidth: '270px',
        //     cell: row => <>
        //         {
        //             row.orderStatus === "Yet to Finalize" ? (
        //                 selectedRows.length == 1 ?
        //                     <div className='row m-1'>
        //                         <input onClick={() => actionClicked("Confirmed", row.orderNumber)} type="submit" value="Finalize" class="btn btn-outline-success btn-sm" />
        //                         <input onClick={() => actionClicked("Rejected", row.orderNumber)} type="reset" value="Cancel" class="btn btn-outline-danger btn-sm ml-2" />

        //                     </div> :
        //                     <div className='row m-1'>
        //                         <input
        //                             type="submit"
        //                             value="Finalize"
        //                             className={`btn btn-outline-success ${isButtonDisabledForRow(row.orderNumber) ? 'disabled' : ''}`}
        //                             disabled={isButtonDisabledForRow(row.orderNumber)}
        //                         />
        //                         <input
        //                             type="reset"
        //                             value="Cancel"
        //                             className={`btn btn-outline-danger ml-2 ${isButtonDisabledForRow(row.orderNumber) ? 'disabled' : ''}`}
        //                             disabled={isButtonDisabledForRow(row.orderNumber)}
        //                         />
        //                     </div>
        //             ) : (
        //                 selectedRows.length == 1 ?
        //                 <div className='row m-1'>
        //                     <input type="submit" disabled value="Finalize" class="btn btn-outline-success btn-sm" />
        //                     <input type="reset" disabled value="Cancel" class="btn btn-outline-danger btn-sm ml-2" />
        //                 </div>:

        //                 <div className='row m-1'>
        //                     <input
        //                         type="submit"
        //                         value="Finalize"
        //                         className={`btn btn-outline-success ${isButtonDisabledForRow(row.orderNumber) ? 'disabled' : ''}`}
        //                         disabled={isButtonDisabledForRow(row.orderNumber)}
        //                     />
        //                     <input
        //                         type="reset"
        //                         value="Cancel"
        //                         className={`btn btn-outline-danger ml-2 ${isButtonDisabledForRow(row.orderNumber) ? 'disabled' : ''}`}
        //                         disabled={isButtonDisabledForRow(row.orderNumber)}
        //                     />
        //                 </div>
        //             )}
        //     </>
        // }
    ];
    const columnsPOLines = [
        {
            name: 'Material ID',
            selector: row => row.materialId,
            sortable: true
        },
        {
            name: 'Material NAME',
            selector: row => row.materialName,
            sortable: true
        },
        {
            name: 'QTY',
            selector: row => row.quantity,
            sortable: true
        },
        {
            name: 'UOM',
            selector: row => row.uom,
            sortable: true
        },
        {
            name: 'Need By Date',
            selector: row => moment(row.needByDate).format('DD-MMM-YYYY'),
            sortable: true
        },
    ];

    const poLinesOverlay = (orderNumber) => {
        setPoNumberValue(orderNumber);
        poLinesList(orderNumber).then(res => {
            setShowLoader(true);
            if (res.status === 200) {
                setDataPOLines(res.data.data);
                setoffcanvas(true);
                setShowLoader(false);
            }
        }).catch((err) => {
            console.log(err)
        });
    }

    const closeSideOverlay = () => {
        setoffcanvas(false);
    };

    const list = () => {
        if (!props.submitData) {
            poList({ type: "softDemand" }).then(res => {
                setShowLoader(false)
                if (res.status === 200) {
                    setData(res.data.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        } else {
            setShowLoader(false)
            setData(props.data)
        }

    }

    const actionClicked = (status, poNumber) => {
        const dataString = poNumber.map(obj => `${obj.orderNumber}`).join(',');
        setShowLoader(true);
        poAction({ poNumber: dataString, action: status }).then(res => {
            if (res.status === 200) {
                setShowLoader(false);
                if (res.data.status.code === 'SUCCESS') {
                    Swal.fire({
                        title: "Success",
                        text: res.data.data,
                        icon: "success"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            list();
                        }
                    })
                }
            }
        }).catch((err) => {
            console.log(err)
        });
    }

    useEffect(() => {
        setShowLoader(true);
        list();
        filtersData()
    }, [props]);
    const filtersData = () => {
        userInfo().then(res => {
            if (res.status === 200) {
                setSiteList(res.data.data.siteIds)
                setCustomerList(res.data.data.customerIds)
                // setShowLoader(false)
            }
        }).catch((err) => {
            console.log(err)
        });
    }
    const onSubmit = (e) => {
        let submitPayload = {
            fromDate: moment(fromDate).add(1, 'day').toISOString(),
            toDate: moment(toDate).add(1, 'day').toISOString(),
            siteId: e.site
        }
        setShowLoader(true)
        purchaseOrderInProduction(submitPayload).then(res => {
            setShowLoader(false);
            if (res.status === 200) {
                setData(res.data.data)
            }
        }).catch((err) => {
            console.log(err)
        });
    }
    const fromdateSelector = (date) => {
        setFromDate(date);
    }
    const todateSelector = (date) => {
        setToDate(date);
    }
    return (
        <div className="farmMaster">
            {/* {partyType === "Supplier" &&
                <>
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h2 class="m-0 text-dark">Purchase Order</h2>
                        </div>
                    </div>
                    <div className='card'>
                        <div className='card-body'>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-md-12 mb-0" style={{}}>
                                        <div className="row">
                                            <div className="col-md-1" style={{ textAlign: 'left', marginTop: 5 }}><strong>Filter By: </strong></div>
                                        </div>
                                        <div className="row mb-0">
                                            {(partyType === "Linesight" || partyType === "Supplier") &&
                                                <div className='col-md-2'>
                                                    <div className="form-group">
                                                        <select class="form-control form-control-sm" name="farmer">
                                                            <option value="">-Select Customer-</option>
                                                            {customerList.map((item) => (
                                                                <option value={item}>{item}</option>
                                                            ))}
                                                        </select>

                                                    </div>
                                                </div>
                                            }
                                            {(partyType === "Customer" || partyType === "Contractor" || partyType === "Linesight") &&
                                                <div className='col-md-2 mb-0'>
                                                    <div className="form-group">
                                                        <select class="form-control form-control-sm" name="uom">
                                                            <option value="">-Select Production Plant-</option>
                                                            {siteList.map((item) => (
                                                                <option value={item}>{item}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-md-2 mb-0">
                                                <Datepicker
                                                    selected={fromDate}
                                                    onChange={fromdateSelector}
                                                    //showTimeSelect
                                                    //setDuration(moment(closeDate).diff(batchSummary.startDate))
                                                    placeholderText="Select From Date"

                                                    //minDate={new Date(moment(batchSummary.startDateTime).format('YYYY-MM-DD'))}
                                                    dateFormat="dd-MMM-yyyy"
                                                    name="fromDate"
                                                    className="form-control form-control-sm"

                                                >
                                                </Datepicker>
                                            </div>
                                            <div className="col-md-2 mb-0">
                                                <Datepicker
                                                    selected={toDate}
                                                    onChange={todateSelector}
                                                    placeholderText="Select To Date"
                                                    dateFormat="dd-MMM-yyyy"
                                                    name="closeDate"
                                                    className="form-control form-control-sm"
                                                >
                                                </Datepicker>
                                            </div>
                                            <div className="col mb-0">
                                                <button style={{ marginTop: 0 }} className="btn btn-sm btn-primary">Submit</button>
                                            </div>
                                        </div>

                                    </div>
                                </div
                                >
                            </form>
                        </div>
                    </div>
                    <div className="card">
                        <div class="card-body">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        pagination
                                        //selectableRows
                                        // selectableRowsComponent={BootyCheckbox}
                                        // onSelectedRowsChange = {handleChange}
                                        dense
                                        selectableRowsHighlight='true'
                                        compact
                                        highlightOnHover='true'
                                        striped
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            } */}
            {/* {partyType !== "Supplier" && */}
            <>
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-8" style={{ display: 'flex', justifyContent: 'end' }}>


                        <ul class="controls" style={{ border: 'none' }}>
                            <li>
                                <button type="button" class="btn btn-primary">
                                    <SiMicrosoftexcel /> Export to Excel
                                </button>
                            </li>
                            {showbuttons &&
                                (
                                    <>
                                        <li><button type="button" class="btn btn-block btn-outline-success btn-sm" onClick={() => actionClicked("Confirmed", selectedRows)}>
                                            Finalize</button></li>
                                        <li><button type="button" class="btn btn-block btn-outline-danger btn-sm" onClick={() => actionClicked("Rejected", selectedRows)}>
                                            Reject</button></li></>

                                )
                            }
                        </ul>


                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        {
                            partyType === "Supplier" &&
                            <>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    pagination
                                    // selectableRowsComponent={BootyCheckbox}
                                    onSelectedRowsChange={handleChange}
                                    dense
                                    selectableRowsHighlight='true'
                                    compact
                                    highlightOnHover='true'
                                    striped
                                />
                            </>
                        }
                        {
                            partyType !== "Supplier" &&
                            <DataTable
                                columns={columns}
                                data={data}
                                pagination
                                selectableRows
                                // selectableRowsComponent={BootyCheckbox}
                                onSelectedRowsChange={handleChange}
                                dense
                                selectableRowsHighlight='true'
                                compact
                                highlightOnHover='true'
                                striped
                            />
                        }
                    </div>
                </div>
            </>
            {/* } */}
            <div id='sideOverlay' className={`side-overlay ${offcanvas ? 'open' : ''}`} style={{ boxShadow: "0 0 7px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 26%)", width: "70%" }}>
                <div className="overlay-content">
                    <i class="fas fa-times-circle close-button" aria-hidden="true" onClick={closeSideOverlay} style={{ fontSize: 20 }}></i>
                    {/* <button className="close-button" >Close</button> */}
                    <div className='row' style={{ marginTop: "2%", marginLeft: "3%", marginRight: "3%" }}>
                        <div className='col-md-12'>
                            <div className='card-body'>
                                <div className='title row' style={{ justifyContent: "left" }}>
                                    <h2 className='p-1 ml-1 m-2 text-dark'>
                                        Purchase Order Lines
                                    </h2>
                                </div>
                                <div className='row mt-4 sideOverlaySummary'>
                                    <div className='col-4'>
                                        <strong>PO #: </strong> {poNumberValue}
                                    </div>
                                </div>
                                <div className='row mt-4' style={{ border: '1px solid black' }}>

                                    <div className='col-12'>
                                        <DataTable
                                            columns={columnsPOLines}
                                            data={dataPOLines}
                                            pagination
                                            //selectableRows
                                            // selectableRowsComponent={BootyCheckbox}
                                            // onSelectedRowsChange = {handleChange}
                                            dense
                                            selectableRowsHighlight='true'
                                            compact
                                            highlightOnHover='true'
                                            striped
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showLoader ?
                <section {...containerProps} style={{ "margin-top": "0px", display: 'inline' }}>
                    {indicatorEl} {/* renders only while loading */}
                </section> : ""
            }
        </div>
    )
}

export default POReport