import DataTable from "react-data-table-component";

import { IoMdArrowRoundBack } from "react-icons/io";
import { useHistory } from 'react-router-dom';
const ApproachingOutofStock = () => {
    const columns = [
        {
            name: "PLANTID",
            selector: "SiteId",
            sortable: true
        },
        {
            name: "PRODUCT ID",
            selector: "PRODUCTID",
            sortable: true
        },
        {
            name: "REQUIRED QTY",
            selector: "REQUIREDQUANTITY",
            sortable: true
        },
        {
            name: "REQUIRED BY DATE",
            selector: "REQUIREDBYDATE",
            sortable: true
        },
        {
            name: "QTY ON HAND",
            selector: "QUANTITYONHAND",
            sortable: true
        },
        {
            name: "QTY TO ORDER",
            selector: "QUANTITYTOORDER",
            sortable: true
        }
    ];
    const data = [];
    const history = useHistory();

    const handleBackClick = () => {
        history.push('/dashboard', { propActiveState: 'inventoryRisks' });
    };
    return (
        <div className="farmMaster">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">INVENTORY APPROACHING OUT OF STOCK</h2>
                </div>
                <div class="col-md-6">
                    <span style={{ display: "flex", justifyContent: "end" }}>
                        <div className="btnBack" onClick={handleBackClick}>
                            <IoMdArrowRoundBack size={20}></IoMdArrowRoundBack>&nbsp; Back
                        </div>
                    </span>
                </div>
            </div>
            <div className="card">
                <div class="card-body">
                    <div className="row">
                        <form className="col-md-12">
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className="form-group">
                                        <label htmlFor>Production Plant Name</label>
                                        <select class="form-control form-control-sm" name="farmer">
                                            <option value="">-Select-</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className="form-group">
                                        <label htmlFor>Production ID</label>
                                        <select class="form-control form-control-sm" name="farmer">
                                            <option value="">-Select-</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className="form-group">
                                        <label htmlFor>Product ID</label>
                                        <select class="form-control form-control-sm" name="farmer">
                                            <option value="">-Select-</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div> <br></br>
                    <div className='row'>
                        <div className='col-md-12'>
                            <DataTable
                                columns={columns}
                                data={data}
                                defaultSortField="batchID"
                                pagination
                                //selectableRows
                                // selectableRowsComponent={BootyCheckbox}
                                // onSelectedRowsChange = {handleChange}
                                dense
                                selectableRowsHighlight='true'
                                compact
                                highlightOnHover='true'
                                striped
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApproachingOutofStock;