import React, { useState } from 'react';
import ReactECharts from 'echarts-for-react'; // Import the React wrapper for ECharts

const InventoryChart = () => {
  const [isError, setIsError] = useState(false); // State to track if there's an error

  // Function to return the chart option
  const getOption = () => {
    try {
      // Define the chart configuration inside the try block
      return {
        title: {
          //text: 'Inventory Status',
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['Inventory Planned', 'Inventory Shortage']
        },
        grid: {
          left: 20,
          top: 25,
          right: 30,
          bottom: 30,
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          name: 'Production Plants',
          nameLocation: 'middle',

          data: ['New York', 'San Francisco', 'Seattle', 'Dallas', 'Philadelphia', 'Phoenix', 'Boston'],
          axisTick: {
            alignWithLabel: true
          },
          axisPointer: {
            type: 'shadow'
          },
          axisLabel: {
            interval: 0,
            rotate: 0, // Keep labels horizontal
            formatter: function (value) {
              // Break the label into multiple lines if it exceeds a certain length
              const maxLength = 15; // Maximum character length per line
              let result = '';
              let currentLine = '';

              value.split(' ').forEach((word, index) => {
                if ((currentLine.length + word.length) >= maxLength) {
                  result += currentLine + '\n'; // Add the current line to the result and start a new line
                  currentLine = word;
                } else {
                  currentLine += (currentLine.length > 0 ? ' ' : '') + word; // Add the word to the current line
                }
              });

              if (currentLine) {
                result += currentLine; // Add any remaining text in currentLine to the result
              }

              return result;
            },

            // Adjust styling as needed
          },
          nameLocation: 'middle',
          nameGap: 30
        },

        yAxis: {
          type: 'value',
          name: 'Materials Count',
          nameLocation: 'middle',
          nameGap: 30,
          axisLabel: {
            formatter: '{value}'
          },
          min: 0,

        },
        series: [
          {
            name: 'Inventory Planned',
            type: 'line',
            data: [55, 90, 100, 40, 30, 85, 40, 45, 30],
            smooth: true, // Make line smooth
            itemStyle: {
              color: 'green',
            },
            lineStyle: {
              type: 'solid'
            }
          },
          {
            name: 'Inventory Shortage',
            type: 'line',
            smooth: true, // Make line smooth
            data: [25, 32, 41, 14, 17, 27, 18, 20, 12],
            itemStyle: {
              color: 'red',
            },
            lineStyle: {
              type: 'dotted'
            }
          }
        ]
      };
    } catch (error) {
      console.error("Error configuring the chart:", error);
      setIsError(true); // Update state to indicate an error occurred
      return {}; // Return an empty configuration to avoid further errors
    }
  };

  // Conditional rendering based on isError state
  if (isError) {
    return <div>Failed to load the chart.</div>;
  }

  return (
    <div id='lineChart' style={{ height: '300px', width: '100%' }}>
      <ReactECharts option={getOption()} style={{ height: '100%', width: '100%' }} />
    </div>
  )
};

export default InventoryChart;
