import DataTable from "react-data-table-component";
import React, { useState, useEffect } from "react";
import { poList, poLinesList } from "../pages/const";
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';
import moment from 'moment';

const RejectedPO = (props) => {
    const [offcanvas, setoffcanvas] = useState(false);
    const [partyType, setPartyType] = useState(sessionStorage.getItem('partyType'));
    const [data, setData] = useState([]);
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="22" color="#333" />,
    });
    const [showLoader, setShowLoader] = useState(false);
    const [poNumberValue, setPoNumberValue] = useState('');
    const [dataPOLines, setDataPOLines] = useState([]);

    const columns = [
        {
            name: 'Purchase Order',
            selector: row => row.orderNumber,
            sortable: true,
            cell: (row) => <a
                style={{
                    width: "100%",
                    lineHeight: 0.5,
                    color: "blue",
                    cursor: 'pointer'
                }}
                onClick={() => poLinesOverlay(row.orderNumber)}
            >
                {row.orderNumber}
            </a>
        },
        partyType === 'Supplier' ? {
            name: 'Customer ID',
            selector: row => row.customerId,
            sortable: true
        } : {
            name: 'Supplier ID',
            selector: row => row.supplierId,
            sortable: true
        },
        partyType === 'Supplier' ? {
            name: 'Customer Name',
            selector: row => row.customerName,
            sortable: true
        } : {
            name: 'Supplier Name',
            selector: row => row.customerName,
            sortable: true
        },
        {
            name: 'Delivery PLANT',
            selector: row => row.customerSiteId,
            sortable: true
        },
        {
            name: 'CREATED BY',
            selector: row => row.createdBy,
            sortable: true
        },
        {
            name: 'CREATED DATE',
            selector: row => row.creationDate,
            sortable: true
        },
        // {
        //     name: 'STATUS',
        //     selector: row => row.orderStatus,
        //     sortable: true
        // }
    ];


    const columnsPOLines = [
        {
            name: 'Material ID',
            selector: row => row.materialId,
            sortable: true
        },
        {
            name: 'Material NAME',
            selector: row => row.materialName,
            sortable: true
        },
        {
            name: 'QTY',
            selector: row => row.quantity,
            sortable: true
        },
        {
            name: 'UOM',
            selector: row => row.uom,
            sortable: true
        },
        {
            name: 'Need By Date',
            selector: row => moment(row.needByDate).format('DD-MMM-YYYY'),
            sortable: true
        },
    ];




    const poLinesOverlay = (orderNumber) => {
        setPoNumberValue(orderNumber);
        poLinesList(orderNumber).then(res => {
            setShowLoader(true);
            if (res.status === 200) {
                setDataPOLines(res.data.data);
                setoffcanvas(true);
                setShowLoader(false);
            }
        }).catch((err) => {
            console.log(err)
        });
    }

    const closeSideOverlay = () => {
        setoffcanvas(false);
    };


    const list = () => {
        if (!props.submitData) {
            poList({ type: "rejected" }).then(res => {
                setShowLoader(false)
                if (res.status === 200) {
                    setData(res.data.data)
                }
            }).catch((err) => {
                console.log(err)
            });
        } else {
            setShowLoader(false)
            setData(props.data)
        }
    }

    useEffect(() => {
        setShowLoader(true);
        list();
    }, []);

    return (
        <div className="farmMaster">
            {/* <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">Finalized Purchase Order</h2>
                </div>
            </div> */}
            {/* <div className="card"> */}
            {/* <div class="card-body"> */}

            <div className='row'>
                <div className='col-md-12'>
                    <DataTable
                        columns={columns}
                        data={data}
                        defaultSortField="batchID"
                        pagination
                        //selectableRows
                        // selectableRowsComponent={BootyCheckbox}
                        // onSelectedRowsChange = {handleChange}
                        dense
                        selectableRowsHighlight='true'
                        compact
                        highlightOnHover='true'
                        striped
                    />
                </div>
            </div>
            {/* </div> */}
            {/* </div> */}
            <div id='sideOverlay' className={`side-overlay ${offcanvas ? 'open' : ''}`} style={{ boxShadow: "0 0 7px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 26%)", width: "70%" }}>
                <div className="overlay-content">
                    <i class="fas fa-times-circle close-button" aria-hidden="true" onClick={closeSideOverlay} style={{ fontSize: 20 }}></i>
                    {/* <button className="close-button" >Close</button> */}
                    <div className='row' style={{ marginTop: "2%", marginLeft: "3%", marginRight: "3%" }}>
                        <div className='col-md-12'>
                            <div className='card-body'>
                                <div className='title row' style={{ justifyContent: "left" }}>
                                    <h2 className='p-1 ml-1 m-2 text-dark'>
                                        Purchase requisitions Lined
                                    </h2>
                                </div>
                                <div className='row mt-4 sideOverlaySummary'>
                                    <div className='col-4'>
                                        <strong>PO #: </strong> {poNumberValue}
                                    </div>
                                </div>
                                <div className='row mt-4' style={{ border: '1px solid black' }}>
                                    <div className='col-12'>
                                        <DataTable
                                            columns={columnsPOLines}
                                            data={dataPOLines}
                                            pagination
                                            //selectableRows
                                            // selectableRowsComponent={BootyCheckbox}
                                            // onSelectedRowsChange = {handleChange}
                                            dense
                                            selectableRowsHighlight='true'
                                            compact
                                            highlightOnHover='true'
                                            striped
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showLoader ?
                <section {...containerProps} style={{ "margin-top": "0px", display: 'inline' }}>
                    {indicatorEl} {/* renders only while loading */}
                </section> : ""
            }
        </div>
    )
}

export default RejectedPO;