import DataTable from "react-data-table-component";
import React, { useEffect, useState, useRef } from 'react';

const MaterialMasterList = () => {

    const columns = [
        {
            name: "Material ID",
            selector: "materialID",
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Material Category",
            selector: "materialCategory",
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Material Name",
            selector: "materialName",
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Material Description",
            selector: "materialDescription",
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Supplier ID",
            selector: "supplierID",
            sortable: true,
            minWidth: '60px'
        },
        {
            name: "Lead Days",
            selector: "leadDays",
            sortable: true,
            minWidth: '50px'
        }
    ];

    const [data, setData] = useState([
        {
            "materialID": "MTRL001",
            "materialName": "Corn",
            "materialCategory": "Raw Material-Corn",
            "materialDescription": "Corn",
            "supplierID": "SUP1",
            "leadDays": "2 Days",
            "openingStock": 4,
            "siteID": "SITE01"
        },
        {
            "materialID": "MTRL002",
            "materialName": "Rice",
            "materialCategory": "Raw Material-Rice",
            "materialDescription": "Rice",
            "supplierID": "SUP1",
            "leadDays": "2 Days",
            "openingStock": 4,
            "siteID": "SITE01"
        },
        {
            "materialID": "MTRL003",
            "materialName": "Chick Peas",
            "materialCategory": "Raw Material-Chick Peas",
            "materialDescription": "Chick Peas",
            "supplierID": "SUP1",
            "leadDays": "2 Days",
            "openingStock": 4,
            "siteID": "SITE01"
        },
        {
            "materialID": "MTRL004",
            "materialName": "Cumin",
            "materialCategory": "Raw Material-Cumin",
            "materialDescription": "Cumin",
            "supplierID": "SUP1",
            "leadDays": "2 Days",
            "openingStock": 4,
            "siteID": "SITE01"
        },
        {
            "materialID": "MTRL005",
            "materialName": "Turmeric",
            "materialCategory": "Raw Material-Turmeric",
            "materialDescription": "Turmeric",
            "supplierID": "SUP1",
            "leadDays": "2 Days",
            "openingStock": 4,
            "siteID": "SITE01"
        },
        {
            "materialID": "MTRL006",
            "materialName": "Pepper",
            "materialCategory": "Raw Material-Pepper",
            "materialDescription": "Pepper",
            "supplierID": "SUP1",
            "leadDays": "2 Days",
            "openingStock": 4,
            "siteID": "SITE01"
        },
        {
            "materialID": "MTRL007",
            "materialName": "Salt",
            "materialCategory": "Raw Material-Salt",
            "materialDescription": "Salt",
            "supplierID": "SUP1",
            "leadDays": "2 Days",
            "openingStock": 4,
            "siteID": "SITE01"
        }
    ]);

    return (
        <>
            <DataTable
                columns={columns}
                data={data}
                //defaultSortField="templateID"
                pagination
                dense
                selectableRowsHighlight='true'
                compact
                highlightOnHover='true'
            />
        </>
    )

}

export default MaterialMasterList;