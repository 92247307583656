import React, { useEffect, useState, useRef } from 'react';
import DataTable from "react-data-table-component";
import { softDemandUpload } from "../pages/const";
import Swal from 'sweetalert2';
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';

const SoftDemandPreview = (tableData) => {
    const columns = [
        {
            name: "Production ID",
            selector: row => row.workId,
            sortable: true,
        },
        {
            name: "Task ID",
            selector: row => row.taskId,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Order Number",
            selector: row => row.orderNumber,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Order Recommended Date",
            selector: row => row.orderRecommendedDate,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Customer ID",
            selector: row => row.customerId,
            sortable: true,
        },
        {
            name: "Customer Production Plant ID",
            selector: row => row.customerSiteId,
            sortable: true,
        },
        {
            name: "Supplier ID",
            selector: row => row.supplierId,
            sortable: true,
        },
        {
            name: "Order Status",
            selector: row => row.orderStatus,
            sortable: true,
        },
        {
            name: "Material Category",
            selector: row => row.materialCategory,
            sortable: true,
        },
        {
            name: "Material ID",
            selector: row => row.materialId,
            sortable: true,
        },
        {
            name: "Material Name",
            selector: row => row.materialName,
            sortable: true,
            minWidth: '300px'
        },
        {
            name: "Material Description",
            selector: row => row.materialDescription,
            sortable: true,
            minWidth: '300px'
        },
        {
            name: "Quantity",
            selector: row => row.quantity,
            sortable: true,
        },
        {
            name: "UOM",
            selector: row => row.uom,
            sortable: true,
        },
        {
            name: "Need By Date",
            selector: row => row.needByDate,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Unit Price",
            selector: row => row.unitPrice,
            sortable: true,
        },
        {
            name: "Discount",
            selector: row => row.discount,
            sortable: true,
        },
        {
            name: "Net Amount",
            selector: row => row.netAmount,
            sortable: true,
        },
        {
            name: "Delivery Production Plant ID",
            selector: row => row.deliverySiteId,
            sortable: true,
        },
        {
            name: "Delivery Instructions ",
            selector: row => row.deliveryInstructions,
            sortable: true,
        },
        {
            name: "Valid",
            selector: row => row.errorExist ? 'NO' : 'YES',
            sortable: true,
        },
        {
            name: "Error",
            selector: row => row.error,
            sortable: true,
            minWidth: '520px'
        }
    ];
    const [data, setData] = useState([]);
    const [errorCount, setErrorCount] = useState();
    const [totalCount, setTotalCount] = useState();
    const customRowStyles = (row) => {
        // console.log("row.errorExist:", row.children[2][0].props.row.errorExist); // Log errorExist value
        return {
            color: row.children[2][0].props.row.errorExist === false ? 'inherit !important' : (row.children[2][0].props.row.errorExist === true ? 'red !important' : ''),
        }
    };
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="22" color="white" />,
    })
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        console.log("tableData", typeof tableData.tableData[0]['errorExist']);
        if (tableData.tableData.length !== 0) {
            setData(tableData.tableData);
            const countWithErrorExist = data.filter(item => {
                // console.log("Processing item:", item.errorExist);
                return item.errorExist === true;
            }).length;
            const totalCount = data.length;
            setErrorCount(countWithErrorExist);
            setTotalCount(totalCount)
            // console.log("Count of objects with errorExist=false:", errorCount);
        }
    })
    const softDemandUploadSubmit = () => {
        setShowLoader(true);
        let obj = {
            data: data
        }
        console.log('obj', obj)
        softDemandUpload(obj).then(res => {
            if (res.status === 200) {
                setShowLoader(false);
                if (res.data.status.code === 'SUCCESS') {
                    Swal.fire({
                        title: "success!",
                        // text: "You clicked the button!",
                        icon: "success"
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    });
                }
            }
        })
    }
    return (
        <>
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">Soft Demand</h2>
                </div>
                <div className='col-sm-6' style={{ display: "flex", justifyContent: "end" }}>
                    {errorCount !== 0 &&
                        <div className='mr-2' style={{ background: "red", borderRadius: "3px", padding: '5px', color: "white", fontWeight: "bold", margin: "1px" }}>
                            <strong>Errors : </strong> {errorCount}/{totalCount}
                        </div>
                    }
                    {/* {errorCount === 0 &&
                        <div className='mr-2' style={{ background: "green", borderRadius: "3px", padding: '5px', color: "white", fontWeight: "bold", margin: "1px" }}>
                            <strong>Valid : </strong> {errorCount}/{totalCount}
                        </div>
                    } */}
                    {/* <input type="submit" onClick={softDemandUploadSubmit} disabled={errorCount !== 0} value="Submit" class="btn btn-sm btn-primary" /> */}
                    <input type="submit" onClick={softDemandUploadSubmit} disabled={errorCount !== 0} class="btn btn-sm btn-primary" />
                    {showLoader ? (
                        <section {...containerProps} style={{ marginTop: "0px", display: 'inline-block', verticalAlign: 'middle' }}>
                            {indicatorEl}
                        </section>
                    ) : (
                        ""
                    )}
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        //selectableRows
                        // selectableRowsComponent={BootyCheckbox}
                        // onSelectedRowsChange = {handleChange}
                        dense
                        selectableRowsHighlight='true'
                        compact
                        highlightOnHover='true'
                        striped
                        customStyles={{
                            rows: {
                                style: customRowStyles
                            }
                        }}
                    />
                </div>
            </div>


        </>
    )
}

export default SoftDemandPreview;