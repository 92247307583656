import DataTable from "react-data-table-component";
import React, { useEffect, useState, useRef } from 'react';

const SiteMastersList = () => {

    const columns = [
        {
            name: "Production Plant ID",
            selector: "siteID",
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Production Plant Name",
            selector: "siteName",
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Customer ID",
            selector: "customerID",
            sortable: true,
            minWidth: '130px'
        },
        {
            name: "Admin Mail ID",
            selector: "adminMailID",
            sortable: true,
            minWidth: '200px'
        },
        {
            name: "Admin Contact",
            selector: "adminContact",
            sortable: true,
            minWidth: '60px'
        },
        {
            name: "Address",
            selector: "address",
            sortable: true,
            minWidth: '50px'
        },
        {
            name: "Action",
            selector: "",
            sortable: true,
            minWidth: '150px'
        }
    ];

    const [data, setData] = useState([
        {
            siteID: "Google Production Plant 1",
            siteName: "Google",
            customerID: "Cus001",
            adminMailID: "google@site.com",
            adminContact: "9832013444",
            address: "ABC,HTYd"
        },
    ]);

    return (
        <>
            <DataTable
                columns={columns}
                data={data}
                //defaultSortField="templateID"
                pagination
                dense
                selectableRowsHighlight='true'
                compact
                highlightOnHover='true'
            />
        </>
    )

}

export default SiteMastersList;