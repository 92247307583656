import DataTable from "react-data-table-component";
import React, { useState, useEffect } from "react";
import Datepicker from 'react-datepicker';
import { productionScheduleList, poLinesList, userInfo, purchaseOrderInProduction } from '../pages/const';
import moment from 'moment';
import { useLoading, Bars, ThreeDots } from '@agney/react-loading';
import { useForm, Controller } from "react-hook-form";
const ProductionSchedule = () => {
    const [partyType, setPartyType] = useState(sessionStorage.getItem('partyType'));
    const [offcanvas, setoffcanvas] = useState(false);
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="22" color="#333" />,
    });
    const [showLoader, setShowLoader] = useState(false);
    const [data, setData] = useState([]);
    const [dataPOLines, setDataPOLines] = useState([]);
    const [poNumberValue, setPoNumberValue] = useState('');
    const [siteList, setSiteList] = useState([])
    const [customerList, setCustomerList] = useState([])
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const { register, handleSubmit, getValues, watch, formState: { errors } } = useForm({
        mode: 'onChange'
    });
    const closeSideOverlay = () => {
        setoffcanvas(false);
    };
    const columnsPOLines = [
        {
            name: 'Material ID',
            selector: row => row.materialId,
            sortable: true
        },
        {
            name: 'Material NAME',
            selector: row => row.materialName,
            sortable: true
        },
        {
            name: 'QTY',
            selector: row => row.quantity,
            sortable: true
        },
        {
            name: 'UOM',
            selector: row => row.uom,
            sortable: true
        },
        {
            name: 'Need By Date',
            selector: row => moment(row.needByDate).format('DD-MMM-YYYY'),
            sortable: true
        },
    ];


    const columns = [
        {
            name: 'ORDER NUMBER',
            selector: "orderNumber",
            sortable: true,
            minWidth: "200px",
            cell: (row) => <a
                style={{
                    width: "100%",
                    lineHeight: 0.5,
                    color: "blue",
                    cursor: 'pointer'
                }}
                onClick={() => poLinesOverlay(row.orderNumber)}
            >
                {row.orderNumber}
            </a>
        },
        partyType === "Supplier" ? {
            name: 'CUSTOMER Name',
            selector: "supplierName",
            sortable: true
        } : {
            name: 'SUPPLIER Name',
            selector: "supplierName",
            sortable: true
        },
        {
            name: 'PRODUCTION SCHEDULE ID',
            sortable: true,
            minWidth: "200px",
            selector: row => (
                <span title={row.productionScheduleId}>
                    <span>{row.productionScheduleId}</span>
                </span>
            )
        },
        {
            name: 'PLANNED START DATE',
            selector: row => moment(row.productionStartDate).format('DD-MMM-YYYY'),
            sortable: true
        },
        {
            name: 'ACTUAL START DATE',
            selector: row => moment(row.actProdStartDate).format('DD-MMM-YYYY'),
            sortable: true
        },
        {
            name: 'PLANNED END DATE',
            selector: row => moment(row.productionEndDate).format('DD-MMM-YYYY'),
            sortable: true
        },
        {
            name: 'ACTUAL END DATE',
            selector: row => moment(row.actProdEndDate).format('DD-MMM-YYYY'),
            sortable: true
        },
        {
            name: 'DELAY IN DAYS',
            selector: "delayInDays",
            sortable: true
        },
        {
            name: 'CURRENT STATUS',
            selector: "currentStatus",
            sortable: true
        }
    ];

    const list = () => {
        setShowLoader(true)
        productionScheduleList({ type: "softDemand" }).then(res => {
            setShowLoader(false)
            if (res.status === 200) {
                setData(res.data.data)
            }
        }).catch((err) => {
            console.log(err)
        });
    }

    useEffect(() => {
        setShowLoader(true);
        list();
        filtersData()
    }, []);

    const filtersData = () => {
        userInfo().then(res => {
            if (res.status === 200) {
                setSiteList(res.data.data.siteIds)
                setCustomerList(res.data.data.customerIds)
                // setShowLoader(false)
            }
        }).catch((err) => {
            console.log(err)
        });
    }


    const poLinesOverlay = (orderNumber) => {
        setPoNumberValue(orderNumber);
        poLinesList(orderNumber).then(res => {
            setShowLoader(true);
            if (res.status === 200) {

                setDataPOLines(res.data.data);
                setoffcanvas(true);
                setShowLoader(false);
            }
        }).catch((err) => {
            console.log(err)
        });
    }
    const onHandleChangeSite = (e) => {

    }
    const onHandleChangeCustomer = (e) => {

    }
    const fromdateSelector = (date) => {
        setFromDate(date);
    }
    const todateSelector = (date) => {
        setToDate(date);
    }

    const onSubmit = (e) => {
        let submitPayload = {
            fromDate: moment(fromDate).add(1, 'day').toISOString(),
            toDate: moment(toDate).add(1, 'day').toISOString(),
            siteId: e.site
        }
        setShowLoader(true)
        purchaseOrderInProduction(submitPayload).then(res => {
            setShowLoader(false);
            if (res.status === 200) {
                setData(res.data.data)
            }
        }).catch((err) => {
            console.log(err)
        });
    }

    return (
        <div className="farmMaster">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h2 class="m-0 text-dark">Production Schedule</h2>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-12 mb-0" style={{}}>
                                <div className="row">
                                    <div className="col-md-1" style={{ textAlign: 'left', marginTop: 5 }}><strong>Filter By: </strong></div>
                                </div>
                                <div className="row mb-0">
                                    {(partyType === "Linesight" || partyType === "Supplier") &&
                                        <div className='col-md-2'>
                                            <div className="form-group">
                                                <select class="form-control form-control-sm" name="farmer" {...register("customer", {
                                                    onChange: (e) => { onHandleChangeCustomer(e) }
                                                })}>
                                                    <option value="">-Select Customer-</option>
                                                    {customerList.map((item) => (
                                                        <option value={item}>{item}</option>
                                                    ))}
                                                </select>

                                            </div>
                                        </div>
                                    }
                                    {(partyType === "Customer" || partyType === "Contractor" || partyType === "Linesight") &&
                                        <div className='col-md-2 mb-0'>
                                            <div className="form-group">
                                                <select class="form-control form-control-sm" name="uom" {...register("site", {
                                                    onChange: (e) => { onHandleChangeSite(e) }
                                                })}>
                                                    <option value="">-Select Production Plant-</option>
                                                    {siteList.map((item) => (
                                                        <option value={item}>{item}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    }
                                    <div className="col-md-2 mb-0">
                                        {/* <Datepicker
                                            placeholderText="Select From Date"
                                            inputFormat="DD/MM/YYYY"
                                            maxDate={new Date()}
                                           
                                            renderInput={(params) => (
                                                <input {...params} required size="small" fullWidth />
                                            )}
                                            name="closeDate"
                                            className="form-control form-control-sm"
                                        >
                                        </Datepicker> */}
                                        <Datepicker
                                            selected={fromDate}
                                            onChange={fromdateSelector}
                                            //showTimeSelect
                                            //setDuration(moment(closeDate).diff(batchSummary.startDate))
                                            placeholderText="Select From Date"

                                            //minDate={new Date(moment(batchSummary.startDateTime).format('YYYY-MM-DD'))}
                                            dateFormat="dd-MMM-yyyy"
                                            name="fromDate"
                                            className="form-control form-control-sm"

                                        >
                                        </Datepicker>

                                    </div>
                                    <div className="col-md-2 mb-0">
                                        <Datepicker
                                            selected={toDate}
                                            onChange={todateSelector}
                                            placeholderText="Select To Date"
                                            dateFormat="dd-MMM-yyyy"
                                            name="closeDate"
                                            className="form-control form-control-sm"
                                        >
                                        </Datepicker>
                                    </div>
                                    <div className="col mb-0">
                                        <button style={{ marginTop: 0 }} className="btn btn-sm btn-primary">Submit</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>

                </div>
            </div>
            <div className="card">
                <div class="card-body">
                    <div className='row'>
                        <div className='col-md-12'>
                            <DataTable
                                columns={columns}
                                data={data}
                                defaultSortField="batchID"
                                pagination
                                dense
                                selectableRowsHighlight='true'
                                compact
                                highlightOnHover='true'
                                striped
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div id='sideOverlay' className={`side-overlay ${offcanvas ? 'open' : ''}`} style={{ boxShadow: "0 0 7px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 26%)", width: "70%" }}>
                <div className="overlay-content">
                    <i class="fas fa-times-circle close-button" aria-hidden="true" onClick={closeSideOverlay} style={{ fontSize: 20 }}></i>
                    {/* <button className="close-button" >Close</button> */}
                    <div className='row' style={{ marginTop: "2%", marginLeft: "3%", marginRight: "3%" }}>
                        <div className='col-md-12'>
                            <div className='card-body'>
                                <div className='title row' style={{ justifyContent: "left" }}>
                                    <h2 className='p-1 ml-1 m-2 text-dark'>
                                        Purchase Order Lines
                                    </h2>
                                </div>
                                <div className='row mt-4 sideOverlaySummary'>
                                    <div className='col-4'>
                                        <strong>PO #: </strong> {poNumberValue}
                                    </div>
                                </div>
                                <div className='row mt-4' style={{ border: '1px solid black' }}>
                                    <div className='col-12'>
                                        <DataTable
                                            columns={columnsPOLines}
                                            data={dataPOLines}
                                            pagination
                                            //selectableRows
                                            // selectableRowsComponent={BootyCheckbox}
                                            // onSelectedRowsChange = {handleChange}
                                            dense
                                            selectableRowsHighlight='true'
                                            compact
                                            highlightOnHover='true'
                                            striped
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showLoader ?
                <section {...containerProps} style={{ "margin-top": "0px", display: 'inline' }}>
                    {indicatorEl} {/* renders only while loading */}
                </section> : ""
            }
        </div>
    )
}

export default ProductionSchedule;